/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://mawyfvc4szhczgcrkt7hx2jai4.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ydtbc346pna7vgwknx4ssxtvwe",
    "aws_cloud_logic_custom": [
        {
            "name": "CountCloudAPI",
            "endpoint": "https://pkevyqjd2l.execute-api.us-west-2.amazonaws.com/ccprod",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:56389769-3f3a-4863-a76e-f739a60a8a34",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_mexn54WfI",
    "aws_user_pools_web_client_id": "17htovg4uln1713nrtmue0fpnp",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "countcloudamplify6b68f80a985a41e381bb9d09fccdev152622-ccprod",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
