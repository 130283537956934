<script setup>
   import '@aws-amplify/ui-vue/styles.css';
   import { House, Collection, CirclePlus, User, Folder } from '@element-plus/icons-vue'
   import {   useAuthenticator } from '@aws-amplify/ui-vue';
   const auth = useAuthenticator();
</script>
<script>
import { Auth, Hub, Logger } from 'aws-amplify'
export default {
  data() {
    return {
      logger: {},
      isAdmin: false,
      isClient: false,
      isProvider: false,
      userName: "",
      userEmail: "",
      userLabel: "Sign In",
      beforeLogin: true
    }
  },
  async created() {
    this.logger = new Logger('My-Logger');
    Hub.listen('auth', this.listener);
    this.isLoggedIn();
  },
  methods: {
    async isLoggedIn() {
      try {
          let user = await Auth.currentAuthenticatedUser();
          let groups = user.signInUserSession.accessToken.payload["cognito:groups"];
          groups = groups ? groups : [];
          if (groups.includes('admin')) {
            this.isAdmin = true;
          }
          else if ( groups.includes('provider')) {
            this.isProvider = true;
          }
          else {
            this.isClient = true;
            // if (this.$route.path == "/") {
            //   this.$router.push('/clientdashboard');
            // }
          }
          
          this.userName = user.username;
          this.userLabel = "Sign Out"
          return true;
      } catch {
          return this.resetAll();
      }
    },
    // handleTopMenu(key, keyPath) {
    handleTopMenu(key) {
      if (key== "buy") {
        window.open("https://www.streetlogic.pro/products/countcloud", '_blank', 'noreferrer');
      }
      if (key== "ticket") {
        window.open("https://www.streetlogic.pro/pages/help-center", '_blank', 'noreferrer');
      } 
      if (key== "help") {
        window.open("https://www.streetlogic.pro/pages/countcloud-help", '_blank', 'noreferrer');
      }
      
      if (key == "signout") {
        this.auth.signOut();
      }     
    },
    resetAll() {
      this.isAdmin = false;
      this.isClient = false;
      this.isProvider = false;
      this.userName = "";
      this.userLabel = "Sign In"
      this.beforeLogin = true;
      return false;
    },
    listener(data) {
          switch (data.payload.event) {
        case 'signIn':
            this.isLoggedIn();
            break;
        case 'signUp':
            this.isLoggedIn();
            break;
        case 'signOut':
            this.$router.push('/');
            this.resetAll();
            break;
        case 'signIn_failure':
            this.resetAll();
            break;
        case 'tokenRefresh':
            this.logger.info('token refresh succeeded');
            break;
        case 'tokenRefresh_failure':
            this.logger.error('token refresh failed');
            break;
        case 'autoSignIn':
            this.isLoggedIn();
            break;
        case 'autoSignIn_failure':
            this.resetAll();
            break;
        case 'configured':
            this.logger.info('the Auth module is configured');
    }

    }

  }
}
</script>
<template>
<el-container>
      <el-aside><img src="./assets/logo_horizontal_inverted_color.svg" style="display:block;width:300px;padding:20px;" />
        <el-menu class="el-menu-vertical-demo" default-active="1" router background-color="#edeff3" border-color="#edeff3" border-width="0px">
          
          <el-menu-item v-show="isClient" index="/clientdashboard"><template #title><el-icon><house /></el-icon><span>Client Dashboard</span></template></el-menu-item>
          <el-menu-item v-show="isClient" index="/client"><template #title><el-icon><collection /></el-icon><span>Library</span></template></el-menu-item>
          <el-menu-item v-show="isAdmin" index="/admin"><template #title><el-icon><house /></el-icon><span>Dashboard</span></template></el-menu-item>
          <el-menu-item v-show="isAdmin" index="/admindownloaded"><template #title><el-icon><download /></el-icon><span>Downloaded Items</span></template></el-menu-item>
          <el-menu-item v-show="isAdmin" index="/admincreate"><template #title><el-icon><circle-plus /></el-icon><span>Create</span></template></el-menu-item>
          <el-menu-item v-show="isAdmin" index="/dropbox"><template #title><el-icon><folder /></el-icon><span>Dropbox</span></template></el-menu-item>
          <el-menu-item v-show="isProvider" index="/provider"><template #title><el-icon><house /></el-icon><span>Dashboard</span></template></el-menu-item>
          <el-sub-menu  v-show="isAdmin">
            <template #title>
            <el-icon><document /></el-icon>
            <span>Reports</span>
          </template>
          <el-menu-item index="/reportunused">Unused Report</el-menu-item>
          <el-menu-item index="/reportavailable">Available Report</el-menu-item>
          <el-menu-item index="/reportmonthlyused">Monthly Used Report</el-menu-item>
          </el-sub-menu> 
          <el-menu-item index="/signout" index-path="/signout" ><template #title><el-icon><user /></el-icon><span>{{ userLabel }}</span></template></el-menu-item>
        </el-menu>
 
       
      </el-aside>
      <el-container>
        <el-header><img src="./assets/countCLOUD-new_300px_black.png" style="width:300px;float:left;padding-top:30px;padding-bottom:30px" title="Count Cloud" />
<el-button v-show="false" v-if="auth.route === 'authenticated'" @click="handleTopMenu('signout')" size="large" style="margin:20px;padding:30px;float:right;">Signout</el-button>
<el-button @click="handleTopMenu('buy')" color="#fb6248" size="large" style="margin:20px;padding:30px;float:right;"><span style="color:#FFF;">Buy Now</span></el-button>
<el-button @click="handleTopMenu('ticket')" size="large" style="margin:20px;padding:30px;float:right;">Open a Ticket</el-button>
<el-button @click="handleTopMenu('help')" size="large" style="margin:20px;padding:30px;float:right;">HELP CENTER</el-button>
</el-header>
        <el-main><router-view/> </el-main>
      </el-container>
        
     
</el-container>
</template>

<style>
.buynow {
  background-color: #fb6248;
  color: #FFF;
}
#app {
  font-family: MrEaves;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  border-radius: 4px;
  
}

@font-face { 
    font-family: 'MrEaves'; 
    src: 
        url('assets/28433.otf')  
        format('opentype'); 
    font-weight: normal; 
    font-style: normal; 
} 

body {
  background-color: #edeff3;
  font-family: 'MrEaves';
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.pricing {
  text-align: left;
}

.pricing h1 {
  text-align: left;
  text-transform: uppercase;
  font-size: 2em;
  padding-top: 0px;
  margin-top: 0px;
}

.el-card {
  text-align: left;
  padding-bottom: 20px;
  margin-bottom: 20px;
  min-height: 300px;
  font-family: 'MrEaves';
}

.el-card h2 {
  text-align: left;
  text-transform: uppercase;
  padding-top: 0px;
  margin-top: 0px;
}

.el-form-item__label {
  font-size: 18px;
  font-weight: bold;
}

.el-form-item {
  font-size: 18px;
}


.el-card li {
  display: list-item;
}
.card-header {
  display: flex;
  font-size: 1.5em;
  font-weight: bold;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
}

.el-aside {
  /* --el-menu-text-color: red;
  --el-menu-bg-color: #393A51;
  --el-menu-fill-color-blank: red; */
 
  --el-bg-color: #000000;
  background-color: #000000;
  min-height: 800px;
}
:root {
  --el-menu-bg-color: #393A51;
}
header {
  margin-bottom: 40px;
}


</style>
