<template>
  <div class="home">
<authenticator>
    <template v-slot="{ user, signOut }">
      <h1>Hello {{ user.username }}!</h1>
      <button @click="signOut">Sign Out</button>
    </template>
    <template v-slot:signIn >
    </template>
  </authenticator>
  </div>
</template>

<script>
// import { API } from 'aws-amplify';
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView'
}
</script>
