import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
Amplify.configure(awsExports);
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import { createApp } from 'vue'
import App from './App.vue'
import AmplifyVue from '@aws-amplify/ui-vue';
import router from './router'

const app = createApp(App);
app.use(router);
app.use(AmplifyVue);
app.use(ElementPlus);

app.component('DatePicker', Datepicker);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.mount('#app');

