import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { Auth } from 'aws-amplify';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/pricing',
    name: 'pricing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pricing" */ '../views/AboutPricing.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    meta: { auth: true , admin: true},
    component: () => import(/* webpackChunkName: "admin" */ '../views/AdminView.vue')
  },  
  {
    path: '/admindownloaded',
    name: 'admindownloaded',
    meta: { auth: true, admin: true },
    component: () => import(/* webpackChunkName: "admin" */ '../views/AdminDownloaded.vue')
  },
  {
    path: '/dropbox',
    name: 'dropbox',
    meta: { auth: true, admin: true },
    component: () => import(/* webpackChunkName: "dropbox" */ '../views/DropboxView.vue')
  },
  {
    path: '/clientdashboard',
    name: 'clientdashboard',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "clientdashboard" */ '../views/ClientDashboard.vue')
  }, 
  {
    path: '/client',
    name: 'client',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "client" */ '../views/ClientView.vue')
  },
  {
    path: '/provider',
    name: 'provider',
    meta: { auth: true, provider: true },
    component: () => import(/* webpackChunkName: "provider" */ '../views/ProviderView.vue')
  },
  {
    path: '/admindetail/:id',
    name: 'admindetail',
    meta: { auth: true, admin: true },
    component: () => import(/* webpackChunkName: "admindetail" */ '../views/AdminDetail.vue')
  },
  {
    path: '/admincreate',
    name: 'admincreate',
    meta: { auth: true, admin: true },
    component: () => import(/* webpackChunkName: "admincreate" */ '../views/AdminCreateCount.vue')
  },
  {
    path: '/clientdetail/:id',
    name: 'clientdetail',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "clientdetail" */ '../views/ClientDetail.vue')
  },
  {
    path: '/clientcreate',
    name: 'clientcreate',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "clientcreate" */ '../views/ClientCreateCount.vue')
  },  
  {
    path: '/reportunused',
    name: 'reportunused',
    meta: { auth: true , admin: true },
    component: () => import(/* webpackChunkName: "reportunused" */ '../views/ReportUnused.vue')
  }, 
  {
    path: '/reportmonthlyused',
    name: 'reportmonthlyused',
    meta: { auth: true , admin: true },
    component: () => import(/* webpackChunkName: "reportmonthlyused" */ '../views/ReportMonthlyUsed.vue')
  }, 
  {
    path: '/reportavailable',
    name: 'reportavailable',
    meta: { auth: true, admin: true },
    component: () => import(/* webpackChunkName: "reportavailable" */ '../views/ReportAvailable.vue')
  }, 
  {
    path: '/providerdetail/:id',
    name: 'providerdetail',
    meta: { auth: true, provider: true },
    component: () => import(/* webpackChunkName: "providerdetail" */ '../views/ProviderDetail.vue')
  },
  {
    path: '/testing',
    name: 'testing',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "testing" */ '../views/Testing.vue')
  },
  {
    path: '/purchase',
    name: 'purchase',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "client" */ '../views/Purchase.vue')
  },
  {
    path: '/purchasedetail/:email',
    name: 'purchasedetail',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "purchasedetail" */ '../views/PurchaseDetail.vue')
  },
  {
    path: '/signout',
    name: 'signout',
    meta: { auth: true },
    component: HomeView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve((to, from, next)=>{
  if (to.meta.auth) {
    Auth.currentAuthenticatedUser().then(
      ()=>{next()}
    ).catch(()=>{
      next( { path:'/'});
    }

    );
  }
  else {
    next();
  }
});

export default router
